import {Swiper, Pagination, Navigation, Autoplay, EffectCreative} from "swiper";

Swiper.use([Pagination, Navigation, Autoplay, EffectCreative]);

document.addEventListener('turbolinks:load', function () {
    const swiper = new Swiper(".swiper-slider", {
        autoplay: true,
        speed: 2000,
        loop: true,
        spaceBetween: 35,
        pagination: {
            clickable: true,
            el: ".swiper-pagination",
        },
        grabCursor: true,
        effect: "creative",
        creativeEffect: {
            prev: {
                shadow: true,
                translate: [0, 0, -400],
            },
            next: {
                translate: ["100%", 0, 0],
            },
        },
    });

    const swiper2 = new Swiper(".swiper-slider-2", {
        autoplay: true,
        speed: 2000,
        loop: true,
        pagination: {
            clickable: true,
            el: ".swiper-pagination-2",
        },
        spaceBetween: 20,
        slidesPerView: 1,
        breakpoints: {
            580: {
                slidesPerView: 2
            },
            980: {
                slidesPerView: 3
            },
            1300: {
                slidesPerView: 4
            }
        }
    });
    if(window.innerWidth < 769) {
        const swiper3 = new Swiper(".swiper-slider-3", {
            autoplay: true,
            speed: 2000,
            loop: true,
            pagination: {
                clickable: true,
                el: ".swiper-pagination-3",
            },
            spaceBetween: 20,
            slidesPerView: 1,
            breakpoints: {
                580: {
                    slidesPerView: 2
                }
            }
        });
    }

})
