document.addEventListener('turbolinks:load', function () {
    const headers = document.querySelectorAll('.service-header')
    headers.forEach(function (header) {
        const section = header.closest('section')
        const content = section.querySelector('.content')
        section.style.height = header.scrollHeight + 'px'
        content.style.height = 'auto'

        header.addEventListener('click', function () {
            if(section.classList.contains('show-content')) {
                section.classList.remove('show-content')
                section.style.height = header.scrollHeight + 'px'
            } else {
                section.classList.add('show-content')
                section.style.height = section.scrollHeight + 'px'
                setTimeout(function () {
                    content.scrollIntoView()
                }, 200)
            }
        })
    })
})