

const closeModal = (target=null) => {
    const modal = document.querySelector('.popup-modal.active')
    if(!modal) {
        return
    }
    if (!target || target && target === modal){
        modal.classList.remove('active')
    }
}

const openModal = function() {
    const modal = document.querySelector('.popup-modal')
    if(!modal) {
        return
    }
    modal.classList.add('active')

    document.querySelector('.modal .icon-close').addEventListener('click', (e) => {
        closeModal()
    })

    document.querySelector('.popup-modal.active')?.addEventListener('click', (e) => {
        closeModal(e.target)
    })
}

window.openModal = openModal


