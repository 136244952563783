window.showMessage = function(container, message) {
    let p = document.createElement('p')
    p.style.color = 'green'
    p.style.border = '2px solid'
    p.style.padding = '15px 20px'
    p.style.borderRadius = '4px'
    p.innerText = message
    container.appendChild(p)
}


document.addEventListener('turbolinks:load', function (){
    const form = document.getElementById('contact-form')
    if(form) {
        const button = form.querySelector('.button')
        form.addEventListener('submit', () => setLoading(button))
        const countries_data = JSON.parse(form.getAttribute('data-countries'))
        const country_select = document.querySelector('select[name="country"]')
        const state_select = document.querySelector('select[name="state"]')
        country_select.addEventListener('change', function() {
            const country = countries_data.countries.filter(function (v){
                return country_select.value === v.country
            })
            state_select.innerHTML = ''
            country[0].states.map(function (state){
                const op = new Option(state, state)
                state_select.appendChild(op)
            })
        })
    }

    const emailInput = document.querySelector('#subscription-form input')
    const btnSubscribe = document.getElementById('btn-subscribe')
    const subscriptionForm = document.getElementById('subscription-form')

    function validateEmail(){
        const validEmail = /^\S+@\S+\.\S+$/.test(emailInput.value)
        if(!validEmail) {
            btnSubscribe.classList.add('disabled')
            return
        }
        btnSubscribe.classList.remove('disabled')
    }

    function setLoading(btn) {
        btn.classList.add('disabled', 'loading')
        const div = document.createElement('div')
        div.classList.add('lds-ring')
        div.innerHTML = '<div></div><div></div><div></div>'
        btn.appendChild(div)
    }

    emailInput.addEventListener('keyup', validateEmail)
    emailInput.addEventListener('change', validateEmail)
    emailInput.addEventListener('paste', validateEmail)

    subscriptionForm.addEventListener('submit', () => setLoading(btnSubscribe))

    const quoteForm = document.querySelector('.quote-form')
    const btnQuote = quoteForm.querySelector('.button')
    quoteForm.addEventListener('submit', () => setLoading(btnQuote))
})
