document.addEventListener('turbolinks:load', function () {
    const navToggleBtn = document.querySelector('.navbar-toggle')
    const mainNav = document.querySelector('.main-nav')
    const body = document.querySelector('body')
    navToggleBtn.addEventListener('click', function () {
        if(navToggleBtn.classList.contains('active')) {
            navToggleBtn.classList.add('closing')
            navToggleBtn.classList.remove('active')
            mainNav.classList.remove('active')
            body.classList.remove('nav-open')
        } else {
            navToggleBtn.classList.add('active')
            mainNav.classList.add('active')
            body.classList.add('nav-open')
        }
    })
    navToggleBtn.addEventListener('animationend', function () {
        if (navToggleBtn.classList.contains('closing')) {
            navToggleBtn.classList.remove('closing')
        }
    })
})
